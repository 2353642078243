


















import { Component, Vue } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import controller from '@/app/ui/controllers/PickupController'

@Component({
  components: {
    LoadingOverlay,
    DataTable,
  },
})
export default class NearbyCourier extends Vue {
  created(): void {
    this.fetchNearbyCourier()
  }

  private fetchNearbyCourier() {
    controller.fetchNearbyCourierList({
      latitude: this.$route.params.latitude,
      longitude: this.$route.params.longitude,
      limit: 100,
    })
  }

  get tableHeader(): string[] {
    return [
      'No.',
      'Full Name',
      'Phone',
      'POS Name',
      'On Duty',
    ]
  }

  get courierItem() {
    return controller.nearbyCourierData.map((courier, i) => {
      return [
        i + 1,
        courier.courierName || '-',
        courier.courierPhone || '-',
        courier.courierPOS || '-',
        courier.onDuty,
      ]
    })
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

}
